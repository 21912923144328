import { graphql } from 'gatsby';
import * as React from 'react';
import Carousel from '../../components/carousel.component';
import Layout from '../../components/layout.component';

function EventsPage(props) {
  return (
    <Layout>
      <Carousel indexPage={props.data.dataJson.indexPage} coverText={`Events`}></Carousel>
      <h1 className="text-6xl">Filler</h1>
      <h1 className="text-6xl">Filler</h1>
      <h1 className="text-6xl">Filler</h1>
      <h1 className="text-6xl">Filler</h1>
      <h1 className="text-6xl">Filler</h1>
      <h1 className="text-6xl">Filler</h1>
      <h1 className="text-6xl">Filler</h1>
      <h1 className="text-6xl">Filler</h1>
      <h1 className="text-6xl">Filler</h1>
      <h1 className="text-6xl">Filler</h1>
      <h1 className="text-6xl">Filler</h1>
    </Layout>
  );
}

export const query = graphql`
  query EventsIndexQuery {
    dataJson {
      indexPage {
        images
      }
      churchName
    }
  }
`;

export default EventsPage;
